@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    scrollbar-color: rgb(134, 134, 134) rgb(134, 134, 134);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(134, 134, 134);
    border-radius: 20px;
  }

  .scrollbar-thumb-white::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

/* For Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #868686 #868686;
  }

  * {
    scrollbar-width: thin;
  }

  .scrollbar-thumb-white {
    scrollbar-color: white white;
  }
}
